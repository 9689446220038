<header class="mobile-invisible">
  <div class="header-cart">
    <a routerLink="/"
      ><img
        width="80"
        height="80"
        src="https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-rm-icon-cart.png"
        loading="lazy"
        alt="Logo Principal Ambientá Brasil - Persianas"
    /></a>
    <div class="checkout-steps">
      <div class="step" [ngClass]="{ active: step >= 1 }">
        <i class="pi pi-shopping-cart"></i>
        <p>Carrinho</p>
      </div>
      <div class="step" [ngClass]="{ active: step >= 2 }">
        <i class="pi pi-truck"></i>
        <p>Entrega</p>
      </div>
      <div class="step" [ngClass]="{ active: step >= 3 }">
        <i class="pi pi-credit-card"></i>
        <p>Pagamento</p>
      </div>
      <div class="step" [ngClass]="{ active: step >= 4 }">
        <i class="pi pi-check"></i>
        <p>Confirmação</p>
      </div>
    </div>
    <div class="header-cart-item">
      <i class="pi pi-lock"></i>
      <p>Ambiente 100% protegido</p>
    </div>
  </div>
</header>

<header class="mobile-visible">
  <div class="header-cart">
    <a routerLink="/"
      ><img
        width="50"
        height="50"
        src="https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-rm-icon-cart.png"
        loading="lazy"
        alt="Logo Principal Ambientá Brasil - Persianas"
    /></a>
    <div class="checkout-steps">
      <div class="step" [ngClass]="{ active: step >= 1 }">
        <i class="pi pi-shopping-cart"></i>
      </div>
      <div class="step" [ngClass]="{ active: step >= 2 }">
        <i class="pi pi-truck"></i>
      </div>
      <div class="step" [ngClass]="{ active: step >= 3 }">
        <i class="pi pi-credit-card"></i>
      </div>
    </div>
    <div class="header-cart-item">
      <i class="pi pi-lock"></i>
    </div>
  </div>
</header>
