import { NgIf } from '@angular/common';
import {
  afterNextRender,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrimeNGModule } from '../../primeng.module';
import { MetaTagsService } from '../../services/meta-tags/meta-tags.service';
import { ProductsService } from '../../services/product/products.service';
import { CarouselProductsComponent } from '../../shared/components/carousel-products/carousel-products.component';
import { SliderSwiperComponent } from '../../shared/components/slider-swiper/slider-swiper.component';
import { YoutubeVideoComponent } from '../../shared/components/youtube-video/youtube-video.component';
import { PageResponse } from '../../shared/models/page/page-response';
import { Product } from '../../shared/models/product/product';
import ProductFilter from '../../shared/models/product/product-filter';
import User from '../../shared/models/user/user';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    NgIf,
    PrimeNGModule,
    YoutubeVideoComponent,
    SliderSwiperComponent,
    CarouselProductsComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  public categories: any[] = [];
  public videos: any[] = [];
  public screenWidth: any;
  public informations: any[] = [];
  public user: User = new User();
  public productsFeatured: Product[] = [];
  public showSkeleton = true;
  public showSkeletonCarrousel = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private metaServices: MetaTagsService,
    private router: Router,
    private productService: ProductsService
  ) {
    afterNextRender(() => {
      this.init();
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      const data = this.activatedRoute.snapshot.data['data'];
      this.videos = data.videos;
      this.showSkeleton = false;
    }, 100);
  }

  async init() {
    this.screenWidth = window.innerWidth;
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_HOME,
        url[0]?.path || '',
        '',
        '',
        null
      );
    });
    this.getProductsFeatured();
  }

  async getProductsFeatured() {
    try {
      const filter: ProductFilter = new ProductFilter();
      filter.inactive = false;
      filter.ignoreMainVariation = true;
      filter.featured = true;

      filter.perPage = 20;
      const pageRes: PageResponse | undefined =
        await this.productService.products(filter);
      if ((pageRes?.totalElements || 0) > 0) {
        this.productsFeatured = pageRes?.content || [];
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.showSkeletonCarrousel = false;
    }
  }

  onClickCategories(item: any) {
    this.router.navigate([item.url]);
  }
}
