import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PrimeNGModule } from '../../../primeng.module';
import { CartService } from '../../../services/cart/cart.service';
import { ToastService } from '../../../services/toast/toast.service';
import { AbstractInfoEnum } from '../../models/app/abstract-info-enum';
import { Pix } from '../../models/checkout/pix';
import Freight from '../../models/freight/freight';
import Order, { orderStatus } from '../../models/order/order';
import OrderTrackingDelivery from '../../models/order/order-tracking-delivery';
import User from '../../models/user/user';
import { SharedModule } from '../../shared.module';
import { CountdownTimerComponent } from '../countdown-timer/countdown-timer.component';
import { ModalPixComponent } from '../modals/modal-pix/modal-pix.component';

export class OrderStatusItem {
  id?: number;
  title?: string;
  date?: string;
  status?: string;
}

export class ShippingCompany {
  company?: string;
  tracking?: string;
  url?: string;
  dtCollect?: Date;
  dtEstimate?: string;
}

export class PaymentData {
  form?: string;
  qtdInstallments?: string;
  orderNumber?: string;
  status?: string;
}

@Component({
  selector: 'app-order-customers',
  standalone: true,
  imports: [
    NgIf,
    PrimeNGModule,
    CountdownTimerComponent,
    ModalPixComponent,
    SharedModule,
  ],
  templateUrl: './order-customers.component.html',
  styleUrl: './order-customers.component.scss',
  providers: [ToastService, MessageService],
})
export class OrderCustomersComponent {
  @Input()
  public set order(order: Order) {
    if (order) {
      this._order = order;
      this.getOrderHistories();
      this.setShippingCompany();
      this.setPaymentData();
    }
  }

  @Input()
  public orderStatusInfo?: Array<AbstractInfoEnum>;

  @Input()
  public user: User | undefined;

  public isMobile = window?.innerWidth < 640;
  public _order?: Order;
  public paymentData: PaymentData = new PaymentData();
  public pix = new Pix();
  public shippingCompany: ShippingCompany = new ShippingCompany();
  public showModalPix = false;
  public statuses: Array<OrderStatusItem> = [];
  public trackingDelivery?: OrderTrackingDelivery;

  constructor(
    private cartService: CartService,
    private toastService: ToastService
  ) {}

  getOrderHistories() {
    this.orderStatusInfo?.forEach((statusInfo) => {
      let status: OrderStatusItem = new OrderStatusItem();
      status.status = statusInfo.id;
      status.title = statusInfo.description || '';

      const statusHistory = this._order?.orderHistories?.find(
        (statusHis) => statusHis.updated === statusInfo.id
      );
      if (statusHistory) {
        status.date = statusHistory.createdAt;
        status.id = statusHistory.id || 0;
      }

      this.statuses.push(status);
    });
  }

  storePickUp() {
    return this._order?.orderShipping?.optionFreight === Freight.STORE_PICKUP;
  }

  private setShippingCompany() {
    this.shippingCompany = {
      company:
        this._order?.orderShipping?.name ||
        this._order?.orderTracking?.carrier ||
        '',
    };

    if (this._order?.orderTracking) {
      this.shippingCompany.tracking = this._order?.orderTracking?.number || '';
      this.shippingCompany.url = this._order?.orderTracking?.url || '';

      if (this._order?.orderTracking?.shippedDate) {
        this.shippingCompany.dtCollect =
          this._order?.orderTracking?.shippedDate;
      }

      if (this._order?.orderTracking?.estimateDate) {
        this.shippingCompany.dtEstimate =
          this._order?.orderTracking?.estimateDate?.substring(0, 10);
      }
    } else {
      if (this._order?.orderShipping?.dateStorePickup) {
        if (
          this._order?.orderShipping?.optionFreight === Freight.STORE_PICKUP
        ) {
          this.shippingCompany.dtEstimate =
            this._order?.orderShipping?.dateStorePickup?.substring(0, 10);
        }
      }
    }
  }

  private setPaymentData() {
    this.paymentData = {};
    if (!!this._order?.orderTransaction) {
      this.paymentData.form =
        this._order?.orderTransaction?.paymentCodeDescription;
      this.paymentData.qtdInstallments =
        this._order?.orderTransaction?.installmentCount?.toString();
      this.paymentData.orderNumber = '';
      this.paymentData.status = this._order?.orderTransaction?.getStatus();
    } else {
      if (
        this._order?.orderPayments &&
        this._order?.orderPayments?.length > 0
      ) {
        this.paymentData.form =
          this._order?.orderPayments[0]?.paymentMethodNormalized;
        this.paymentData.qtdInstallments =
          this._order?.orderPayments[0]?.installments?.toString();
      } else {
        this.paymentData.form = '';
        this.paymentData.qtdInstallments = '';
      }

      if (!!this._order?.marketplaceTransaction) {
        this.paymentData.orderNumber =
          this._order?.marketplaceTransaction?.marketPlaceNumber;
      } else {
        this.paymentData.orderNumber = '';
      }
    }

    if (this._order?.orderTransaction?.pix) {
      this.pix.pixData = this._order?.orderTransaction?.pix;
      this.pix.base64QrCode = this._order?.orderTransaction?.base64QrCode;
      this.pix.value = this.getOrderValue();
    }
  }

  openMapsRM() {
    const url = 'https://g.page/rmpolicarbonatos?share';
    window.open(url, '_blank');
  }

  canSendTrackingDelivery() {
    return (
      this.user?.isAdmin &&
      this.isInvoiced() &&
      this.isMercadoLivre() &&
      !this.shippingCompany.tracking
    );
  }

  isMercadoLivre() {
    return (
      this._order?.marketplaceTransaction &&
      this._order?.marketplaceTransaction.marketPlace === 'MERCADO_LIVRE'
    );
  }

  isCanceled() {
    return this._order?.currentStatus === orderStatus.CANCELED;
  }

  isPending() {
    return this._order?.currentStatus === orderStatus.PENDING;
  }

  isInvoiced() {
    return this._order?.currentStatus === orderStatus.INVOICED;
  }

  isSent() {
    return this._order?.currentStatus === orderStatus.PAID_WAITING_DELIVERY;
  }

  isConcluded() {
    return this._order?.currentStatus === orderStatus.CONCLUDED;
  }

  hasNFE() {
    return this.isInvoiced() || this.isSent() || this.isConcluded();
  }

  productHaveVolume(): boolean {
    let have = false;
    this._order?.cartProducts?.forEach((item) => {
      if (
        item &&
        item?.product &&
        item?.product?.volumes &&
        item?.product?.volumes?.length > 0
      ) {
        have = true;
      }
    });
    return have;
  }

  getSubtotal() {
    const value = this._order?.cartProducts?.reduce((old, current) => {
      return old + (current.amount || 1) * (current.value || 0);
    }, 0.0);
    return value;
  }

  getOrderValue() {
    const value = this.getSubtotal() || 0;
    const freight = this._order?.freight || 0;
    const discount = this.getDiscount() || 0;
    const interest = this._order?.interestValue || 0;
    return +value + freight + interest - +discount;
  }

  getDiscount() {
    return this._order?.discount;
  }

  pixPaymentAccept() {
    window.location.reload();
  }

  downloadNFE() {
    window.open(this._order?.orderNFE?.attachmentOrderNFE?.url, '_blank');
  }

  onClickPayment() {
    if (this._order?.orderTransaction?.pix) {
      this.showModalPix = true;
    } else if (this._order?.orderTransaction?.paymentLink) {
      window.open(this.order?.orderTransaction?.paymentLink, '_blank');
    }
  }

  async generateOtherOrder() {
    let valid = true;
    this._order?.cartProducts?.forEach((c) => {
      if (
        !c.product?.enabled ||
        (c.product.stockBalance === 0 && c.product.canBeGeneratedWithStockZero)
      ) {
        valid = false;
        return;
      }
    });

    if (valid) {
      await this.cartService.generateNewOrder(
        this._order?.cartProducts || [],
        true
      );
      this.cartService.updateCartState(true, false);
      this.cartService.goToCart();
    } else {
      this.toastService.error(
        'Existem produtos que não estão mais disponíveis. É necessario gerar o pedido manualmente!'
      );
    }
  }
}
