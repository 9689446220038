import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { InfoCategory } from '../../shared/models/app/info-category';
import { SubCategoriesEnum } from '../../shared/models/app/info-enum';
import { PageResponse } from '../../shared/models/page/page-response';
import { Product } from '../../shared/models/product/product';
import ProductFilter from '../../shared/models/product/product-filter';
import { AppInfoService } from '../app-info/app-info.service';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  public static readonly SKU_PRODUCT_BANDO_DOUBLE = '12130';
  public static readonly SKU_PRODUCT_BANDO_ROLO = '20194';
  public static readonly SKU_PRODUCT_MOTOR = '19632';

  private valueMotor = 0;
  private valueBandoDouble = 0;
  private valueBandoRolo = 0;

  constructor(
    private appInfoService: AppInfoService,
    private httpService: HttpService
  ) {}

  async products(filter: ProductFilter): Promise<PageResponse | undefined> {
    let response: PageResponse = new PageResponse();
    return this.httpService
      .post(`v2/product/pageable/list/`, filter)
      .pipe(
        map(async (res: any) => {
          if (res?.content) {
            const products: Array<Product> = [];
            let newProduct: Product;
            const categories: InfoCategory[] =
              await this.appInfoService.getCategories();
            res.content.forEach(async (productBanco: any) => {
              newProduct = new Product();
              newProduct.fromJson(productBanco, categories);
              products.push(newProduct);
            });
            response.content = products;
            response.totalElements = res.totalElements;
          }
          return response;
        }),
        catchError((error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao buscar os produtos.';
          return throwError(msg);
        })
      )
      .toPromise();
  }

  public async get(sku: string): Promise<Product | undefined> {
    const categories: InfoCategory[] =
      await this.appInfoService.getCategories();
    const product: Product = new Product();
    return this.httpService
      .get(`product/${sku}`)
      .pipe(
        map((res: any) => {
          if (res) {
            product.fromJson(res, categories);
          }
          return product;
        }),
        catchError((error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao buscar esse produto. Tente novamente em alguns minutos!';
          return throwError(msg);
        })
      )
      .toPromise();

    // return new Promise((resolve, reject) => {
    //   this.httpService.get(`product/${sku}`).subscribe({
    //     next: (res: any) => {
    //       if (res) {
    //         product.fromJson(res, categories);
    //       }
    //       resolve(product);
    //     },
    //     error: (error: any) => {
    //       const msg =
    //         error?.error?.message ||
    //         error?.error?.error ||
    //         'Ocorreu um erro ao buscar esse produto. Tente novamente em alguns minutos!';
    //       reject(msg);
    //     },
    //   });
    // });
  }

  getValueMotor(): number {
    return this.valueMotor;
  }

  getValueBandoDouble(): number {
    return this.valueBandoDouble;
  }

  getValueBandoRolo(): number {
    return this.valueBandoRolo;
  }

  getValueTotalBando(width: number, model: string): number {
    return this.isPersianaDouble(model)
      ? this.getValueTotalBandoDouble(width)
      : this.getValueTotalBandoRolo(width);
  }

  getValueTotalBandoDouble(width: number): number {
    const valueBando: number = this.getValueBandoDouble();
    let valueTotal: number = width * valueBando;
    return valueTotal;
  }

  getValueTotalBandoRolo(width: number): number {
    const valueBando: number = this.getValueBandoRolo();
    let valueTotal: number = width * valueBando;
    return valueTotal;
  }

  async getValueMotorBando() {
    if (this.valueMotor === 0) {
      try {
        const productMotor = await this.get(ProductsService.SKU_PRODUCT_MOTOR);
        this.valueMotor = productMotor?.value || 1000;
      } catch (error) {
        this.valueMotor = 1000;
      }
    }

    if (this.valueBandoDouble === 0) {
      try {
        const productBando = await this.get(
          ProductsService.SKU_PRODUCT_BANDO_DOUBLE
        );
        this.valueBandoDouble = productBando?.value || 30;
      } catch (error) {
        this.valueBandoDouble = 30;
      }
    }

    if (this.valueBandoRolo === 0) {
      try {
        const productBando = await this.get(
          ProductsService.SKU_PRODUCT_BANDO_ROLO
        );
        this.valueBandoRolo = productBando?.value || 30;
      } catch (error) {
        this.valueBandoRolo = 30;
      }
    }
  }

  isPersianaRolo(model: string) {
    return model === SubCategoriesEnum.ROLO;
  }

  isPersianaDouble(model: string) {
    return model === SubCategoriesEnum.DOUBLE_VISION;
  }
}
